import React, {useEffect} from "react";
import "../styles/ScreenFormat.css"

const ScreenFormat = ({patientName, doctorName}) =>
{
    const customCss = `
        #root
        {
            width: 100% !important;
            min-height: 100% !important;
            height: 100% !important;
        }
        
        #root .container
        {
            min-width: 100% !important;
            padding: 0 !important;     
        }
        
        html
        {
            background-color: black !important;  
            min-height: 100% !important;        
        }
        
        body
        {
            overflow: hidden !important;
            min-height: 100% !important;   
            height: 100% !important;   
        }
    `

    useEffect(() => {

    }, [patientName])

    return(
        <React.Fragment>
            <div className={"bg-black w-100"} onMouseLeave={() => {
                if(document.getElementById("names")){
                    setTimeout(() => {
                        document.getElementById("names").style.visibility = "hidden"
                    }, 250)
                }
            }}
            onMouseOver={() => {
                if(document.getElementById("names"))
                {
                    setTimeout(() => {document.getElementById("names").style.visibility = "visible"}, 100)
                }
            }}
            >
                <div className={"position-absolute top-0 start-0 w-100"} id={"names"} style={{zIndex: 4}}>
                    <div className={"position-absolute start-0 top-0 ms-5 mt-5 text-white"}>
                        <h4 className={"text-light"}><span className={"fw-bold"}>Responsável: </span>{doctorName}</h4>
                        <h6 className={"fw-bold"}>{patientName}</h6>
                    </div>
                </div>
                <div className={"d-flex w-100"}>
                    <div id={"remote"} className={"d-flex justify-content-center w-100"}>
                        <div className={"position-absolute end-0 top-0 me-5 mt-5 drag"} id={"local"}>
                        </div>
                    </div>
                </div>
            </div>
            <style>{customCss}</style>
        </React.Fragment>
    );
}

export default ScreenFormat;
