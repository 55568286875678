import React from 'react';

const CustomOverlay = ({show = false, backgroundColor = "black", textColor = "white", content = "", icon = false}) => {
    return (
        <React.Fragment>
            {show && <div
                className={"position-absolute h-100 w-100 z-0 top-0 opacity-75 d-flex justify-content-center align-items-center text-center"}
                style={{backgroundColor: backgroundColor, color: textColor}}>
                <div className={"d-grid"}>
                    <div>
                        {icon}
                    </div>
                    <div>
                        <p>{content}</p>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    );
}

export default CustomOverlay;
