import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import {FloatingLabel, ToastContainer} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import Image from "react-bootstrap/Image";
import Logo from "../assets/images/logo-white.png";
import InputMask from "react-input-mask";
import {useFormik} from "formik";
import Alert from "react-bootstrap/Alert";
import api from "../helpers/api";
import * as Yup from "yup";
import CustomToast from "../components/CustomToast";

const YEAR = new Date().getFullYear();
const Recovery = () => {
    const [error, setError] = useState(null);
    const [tokenInput, setTokenInput] = useState(false);
    const [toast, setToast] = useState({});
    const navigate = useNavigate();

    const {handleSubmit, values, handleChange, touched, errors} = useFormik({
        initialValues: {
            login: '',
            newPassword: '',
        },
        validationSchema: Yup.object().shape({
            login: Yup.string().required("Informe seu CPF"),
            newPassword: Yup.string().required("Informe sua senha"),
        }),
        onSubmit: async (v) => {
            const {login, newPassword, token} = v;
            try {
                if (!tokenInput) {
                    await api.post('app/usuario/senha/criar', {
                        login, newPassword
                    })
                    setTokenInput(true);
                } else {
                    await api.post('app/usuario/senha/criar', {
                        login, token
                    })
                    setToast({
                        show: true,
                        title: "Sucesso",
                        variant: "success",
                        content: "Senha atualizada com sucesso",
                        delay: toast.delay
                    })

                    navigate('/');
                }
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([key, value]) => {
                        setError(value, key);
                    })
                } else {
                    setError('Erro desconhecido');
                }
            }
        }
    })

    return (
        <Container className={'d-flex justify-content-center'}>
            <div className={"sticky-bottom"}>
                <ToastContainer id={"toastcontainer"} position={"top-center"} className={"p-3"} style={{zIndex: 1}}>
                    {toast.show && <CustomToast delay={toast.delay} title={toast.title} content={toast.content}
                                                variant={toast.variant} show={toast.show} isHTML={toast.isHTML}/>}
                </ToastContainer>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={'d-flex flex-column gap-3 mt-2'} style={{maxWidth: 480}}>
                    <div className={'text-center'}>
                        <Image src={Logo} className={'img-fluid w-50'}/>
                        <h5 className='text-white'>Criar uma nova senha</h5>
                        <p className='text-secondary'>
                            Se esse for o seu primeiro acesso você poderá criar a sua senha por aqui. Caso você tenha
                            perdido sua senha atual é possível solicitar a criação de uma nova senha de acesso à sua
                            conta.
                        </p>
                    </div>
                    {!tokenInput &&
                        <React.Fragment>
                            <Form.Group>
                                <React.Fragment>
                                    {error && (<Alert variant={'danger'}>{error}</Alert>)}
                                </React.Fragment>
                                <FloatingLabel label={"CPF"}>
                                    <InputMask
                                        placeholder={'CPF'}
                                        mask="999.999.999-99"
                                        id='login'
                                        name='login'
                                        value={values.login}
                                        onChange={handleChange}
                                    >
                                        {(inputProps) => <Form.Control type="text" {...inputProps} />}
                                    </InputMask>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                                {touched.login && errors.login ? (
                                    <span className="error">{errors.login}</span>
                                ) : null}
                            </Form.Group>
                            <Form.Group>
                                <FloatingLabel label={"Digite sua nova senha"}>
                                    <Form.Control type="password"
                                                  placeholder="Digite sua nova senha"
                                                  value={values.newPassword}
                                                  id='newPassword'
                                                  name='newPassword'
                                                  maxLength={6}
                                                  onChange={handleChange}/>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                                {touched.newPassword && errors.newPassword ? (
                                    <span className="error">{errors.newPassword}</span>
                                ) : null}
                            </Form.Group>
                        </React.Fragment>
                    }
                    {tokenInput &&
                        <Form.Group>
                            {tokenInput &&
                                <Alert variant={'success'}>Insira abaixo o código enviado por e-mail e SMS.</Alert>}
                            <FloatingLabel label={"Token"}>
                                <Form.Control type="text"
                                              placeholder="Digite o código"
                                              value={values.token}
                                              id='token'
                                              name='token'
                                              maxLength={6}
                                              onChange={handleChange}/>
                            </FloatingLabel>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                    }
                    <Button className={"primary text-black"}
                            type="submit"
                            size={"lg"}
                    >
                        Criar Senha
                    </Button>
                    <Link className={'text-primary custom-a text-center my-3'} to={'/login'}>Voltar para login</Link>
                    <small className={'text-secondary small text-center'}> © {YEAR} | TokSaúde</small>
                </div>
            </form>
        </Container>
    )
}

export default Recovery;
