import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card, FloatingLabel} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import api from "../helpers/api";
import Logo from "../assets/images/logo-white.png"
import Image from "react-bootstrap/Image";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";

const Account = () => {
    const [error, setError] = useState(null);
    const [toast, setToast] = useState({});
    const navigate = useNavigate();
    const {user} = useAuth();

    const {handleSubmit, values, handleChange, touched, errors} = useFormik({
        initialValues: {
            login: user.Login,
            password: '',
            newPassword: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required("Informe sua senha").length(6, "Sua senha deve ser formada por 6 dígitos"),
            newPassword: Yup.string().required("Informe sua nova senha")
                .length(6, "Sua senha deve ser formada por 6 dígitos")
                .test('passwords-match', 'As senhas não podem ser iguais', function (value) {
                    return value !== this.parent.password;
                }),
        }),
        onSubmit: async (values) => {
            try {
                const {login, password, newPassword} = values;
                await api.put('app/usuario/senha/trocar', {
                    login, password, newPassword
                });

                setToast({
                    show: true,
                    title: "Sucesso",
                    variant: "success",
                    content: "Senha atualizada com sucesso.",
                    delay: toast.delay
                })
                navigate('/');
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([key, value]) => {
                        setError(value, key);
                    })
                }
            }
        }
    })

    return (
        <React.Fragment>
            <Container className={"d-flex justify-content-center"}>
                <form onSubmit={handleSubmit}>
                    <Row style={{maxWidth: 480}}>
                        <Col className={"text-center"}>
                            <Image src={Logo} className={'img-fluid w-50'}/>
                        </Col>
                        <Card className={'p-4 bg-transparent border-2 border-dark-50 gap-3'}>
                            <h5 className='text-white'>Trocar Senha</h5>
                            {error && <Alert variant={'danger'}>{error}</Alert>}
                            <Form.Group>
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                            <Form.Group>
                                <FloatingLabel label={"Senha atual"}>
                                    <Form.Control type="password"
                                                  placeholder={'Nova senha'}
                                                  value={values.password}
                                                  id='password'
                                                  maxLength={6}
                                                  onChange={handleChange}/>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                                {touched.password && errors.password ? (
                                    <span className="error">{errors.password}</span>
                                ) : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                            <Form.Group>
                                <FloatingLabel label={"Nova senha"}>
                                    <Form.Control type="password"
                                                  placeholder={'Nova senha'}
                                                  value={values.newPassword}
                                                  id='newPassword'
                                                  maxLength={6}
                                                  onChange={handleChange}/>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                                {touched.newPassword && errors.newPassword ? (
                                    <span className="error">{errors.newPassword}</span>
                                ) : null}
                            </Form.Group>
                            <Button variant="primary"
                                    type="submit"
                            >
                                Trocar Senha</Button>
                            <Button variant="outline-danger"
                                    onClick={() => {
                                        navigate('/')
                                    }}>Voltar</Button>
                        </Card>
                    </Row>
                </form>
            </Container>
        </React.Fragment>
    )

}

export default Account;
