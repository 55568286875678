import {Card, Modal, ToastContainer} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useLocation, useNavigate} from "react-router-dom";
import Bro from '../assets/images/bro-sn.svg';
import Image from 'react-bootstrap/Image';
import api from "../helpers/api";
import React, {useCallback, useEffect, useRef, useState} from "react";
import moment from "moment";
import CustomToast from "../components/CustomToast";
import MediaTest from "../components/video/tests/MediaTest";

const WaitingRoom = () => {
    const [appointment, setAppointment] = useState({});
    const [token, setToken] = useState();
    const [modal, setModal] = useState(false);
    const [toast, setToast] = useState({})
    const [test, setTest] = useState(false)
    const location = useLocation();
    const {AppointmentId} = location.state;
    const {
        dateAppointment,
        patientName,
        status,
    } = {...appointment};

    const navigate = useNavigate();
    const formattedDate = moment(dateAppointment).format('DD/MM/YYYY [às] HH:mm');

    const getAppointment = useCallback(async () => {
        try {
            const resp = await api.get(`video/atendimento/${AppointmentId}`)
            const {data} = {...resp.data};
            setAppointment(data);
            if (data.status === 6) {
                clearInterval(intervalRef.current);
                const videoRoom = await api.get(`video/atendimento/${AppointmentId}/token`);
                const {roomToken} = {...videoRoom.data.data};
                setToken(roomToken);
                await setToken({
                    token: videoRoom.data.data["roomToken"],
                    name: videoRoom.data.data["roomName"],
                    patientName: `${data.patientName}${data.buyer.age}`
                });
            } else {
                await setToken({token: "placeholder", name: "placeholder", patientName: `placeholder`});
            }
        } catch (err) {
            // console.error(err)
        }
    }, [AppointmentId]);

    const deleteAppointment = () => {
        setToast({show: false, delay: 3000})
        try {
            api.delete(`video/atendimento/${AppointmentId}`)
                .then();
            setModal(false);
            setToast({
                show: true,
                title: "Sucesso",
                variant: "success",
                content: "Sua consulta foi cancelada! Estaremos aqui se precisar!",
                delay: toast.delay
            })
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } catch (err) {
            const text = err.response.data.errors[0]
            setToast({show: true, variant: "danger", title: "Erro", content: text, delay: 5000});
        }
    }
    const openTests = () => {
        setTest(true);
    }

    useEffect(() => {
        getAppointment().then();
    }, []);

    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (!dateAppointment) {
                return false;
            }
            const diff = moment().diff(dateAppointment, 'seconds');
            if (diff >= 0) {
                getAppointment().then();
                console.log('checking')
            }
        }, 30000);
        return () => clearInterval(intervalRef.current);
    });

    return (<React.Fragment>
            {modal &&
                <Modal show={modal} onHide={() => {
                    setModal(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancelar agendamento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>Sua consulta será cancelada. Deseja confirmar?</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={"bg-tok fw-bold"} onClick={deleteAppointment}>Sim</Button>
                        <Button className={"bg-danger fw-bold"} onClick={() => {
                            setModal(false)
                        }}>Não</Button>
                    </Modal.Footer>
                </Modal>}
            <div>
                <ToastContainer id={"toastcontainer"} position={"top-center"} className={"p-3"} style={{zIndex: 1}}>
                    {toast.show && <CustomToast delay={toast.delay} title={toast.title} content={toast.content}
                                                variant={toast.variant} show={toast.show} isHTML={toast.isHTML}/>}
                </ToastContainer>
            </div>

            {!test &&
                <React.Fragment>
                    <Container>
                        <Row className={"text-center p-4 text-white"}>
                            <Col>
                                <h1>Sala de Espera</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{span: 8, offset: 2}}>
                                <Card className={"border-2 border-primary bg-transparent"}>
                                    <Row className={"text-center"}>
                                        <Col>
                                            <Image src={Bro} style={{maxWidth: '440px'}} className={"w-100"}/>
                                            <h6 className='text-secondary'>Teleconsulta
                                                agendada para {patientName} para o dia {formattedDate}</h6>
                                        </Col>
                                    </Row>
                                    <Row className={'text-center py-4'}>
                                        <Col>
                                            <Button variant={'outline-primary'}
                                                    className={"fw-bold"}
                                                    style={{width: '300px'}}
                                                    onClick={() => {
                                                        setModal(true)
                                                    }}>Cancelar Agendamento</Button>
                                            {status === 6 &&
                                                <Button className={'bg-primary text-black fw-bold'}
                                                        style={{width: '300px'}}
                                                        onClick={openTests}>Entrar na Sala</Button>
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row >
                            <Col className={'text-center py-4'}>
                                <Button variant={"outline-danger"}
                                        style={{width: '240px'}}
                                        onClick={() => {
                                            setTimeout(() => {
                                                navigate("/")
                                            }, 1000)
                                        }}>Voltar</Button>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            }
            {test && <MediaTest token={token}/>}
        </React.Fragment>
    )
}

export default WaitingRoom;
