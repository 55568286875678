import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BsCameraVideo} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";
import moment from "moment/moment";

const CustomPagination = ({outData = [{}], changeDate = ""}) => {
    const [data, setData] = useState([{}]);
    const [pagination, setPagination] = useState();
    const [sort, setSort] = useState(false);
    const [date, setDate] = useState("");
    const navigate = useNavigate();

    /**
     * @description Faz a comparação de duas strings retirando ":" e "-" retornando verdadeiro ou falso
     * @param string1
     * @param string2
     * @returns {boolean}
     */
    const cleanString = (string1, string2) => {
        return string1.toString().replaceAll(":", "").replaceAll("-", "").includes(string2.replaceAll("-", "").replaceAll(":", ""));
    }

    const handleChange = () => {
        setData(outData);
        const newdata = outData.filter((item) => {
            if (cleanString(item["ScheduleAt"], changeDate)) {
                return item;
            }
        })
        setPagination(BuildPagination( newdata, navigate));
    }

    useEffect(() => {
        setData(outData);
        outData.reverse()
        setPagination(BuildPagination( outData, navigate))
        setDate(changeDate);
        handleChange();
    }, [outData, changeDate]);

    return (
        <React.Fragment>
            <Container>
                <Row xs={3}><Col><Button className={"bg-transparent border-0 text-white"} onClick={() => {
                    if (sort) {
                        outData.reverse();
                       /* setPagination(BuildPagination(pageCounter, outData, navigate))*/
                        handleChange()
                    } else {
                        outData.reverse()
                       /* setPagination(BuildPagination(pageCounter, outData, navigate))*/
                        handleChange()
                    }
                    setSort(!sort)
                }}>Data {!sort && <AiOutlineDown/>} {sort && <AiOutlineUp/>}</Button></Col>
                </Row>
                <Row>
                    <Col>
                        <hr/>
                    </Col>
                </Row>
            </Container>
            {pagination}
            {false}
        </React.Fragment>
    );
}

function BuildPagination(pageData, navigate) {
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        {pageData.map((item, index) => {
                            //Se o status for diferente de 1,6, não cria
                            if(item.Status !== 1 && item.Status !== 6)
                                return;
                                return (
                                    <Row key={index}>
                                        <Col>
                                            <Card
                                                className={"py-2 my-2 mb-2 bg-transparent border-1 border-secondary appointment-card"}
                                                onClick={() => {
                                                    navigate('/waitingroom', {
                                                        state: {AppointmentId: item.AppointmentId},
                                                        replace: true
                                                    })
                                                }}>
                                                <Container>
                                                    <Row xs={2} className={"d-flex align-items-center text-white"}>
                                                        <Col><strong
                                                        >{moment(item.ScheduleAt).format('DD/MM/YYYY HH:mm')}</strong>
                                                        </Col>
                                                        <Col className={"d-grid align-items-center"}>
                                                            <Button
                                                                className={"d-flex align-items-center justify-content-center gap-2 w-100 fw-bold border-0 bg-transparent text-white"}>
                                                                <BsCameraVideo className={"me-1"}/>Sala de Vídeo</Button>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Card>
                                        </Col>
                                    </Row>
                                );

                        })}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default CustomPagination;
