import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Alert from "react-bootstrap/Alert";
import {FloatingLabel} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import Logo from "../assets/images/logo-white.png"
import api from "../helpers/api";
import {useAuth} from "../hooks/useAuth";
import InputMask from 'react-input-mask';
import {useFormik} from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";

const YEAR = new Date().getFullYear();
const Login = () => {
    const [error, setError] = useState(null);
    const {Login} = useAuth();

    const {handleSubmit, errors, values, touched, handleChange} = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            login: Yup.string().required("Informe seu CPF"),
            password: Yup.string().required("Informe sua senha"),
        }),
        onSubmit: async (v) => {
            try {
                const {login, password} = v;
                const resp = await api.post('app/usuario/autenticar', {login, password});
                const {data} = {...resp.data};
                Login(data);
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([key,value]) => {
                        setError(value, key);
                    })
                }
            }
        }
    })

    return (
        <Container className={'d-flex justify-content-center'}>
            <form onSubmit={handleSubmit}>
                <Row className={'d-flex flex-column gap-3 mt-2'} style={{maxWidth: 480}}>
                    <div className={'text-center'}>
                        <Image src={Logo} className={'img-fluid w-50'}/>
                        <h5 className={'text-white my-4'}>Atendimento por Telemedicina</h5>
                    </div>
                    <Form.Group>
                        <React.Fragment>
                            {error && (<Alert variant={'danger'}>{error}</Alert>)}
                        </React.Fragment>
                        <FloatingLabel label={"CPF"}>
                            <InputMask
                                placeholder={'CPF'}
                                mask="999.999.999-99"
                                id='login'
                                value={values.login}
                                onChange={handleChange}
                            >
                                {(inputProps) => <Form.Control type="text" {...inputProps} />}
                            </InputMask>
                        </FloatingLabel>
                        <Form.Text className="text-muted"></Form.Text>
                        {touched.login && errors.login ? (
                            <span className="error">{errors.login}</span>
                        ) : null}
                    </Form.Group>
                    <Form.Group>
                        <FloatingLabel label={"Senha"}>
                            <Form.Control type="password" placeholder="Digite sua senha"
                                          id='password'
                                          value={values.password}
                                          onChange={handleChange}
                                          maxLength={6}
                            />
                        </FloatingLabel>
                        <Form.Text className="text-muted"></Form.Text>
                        {touched.password && errors.password ? (
                            <span className="error">{errors.password}</span>
                        ) : null}
                    </Form.Group>
                        <Button className={'primary'}
                                type="submit"
                                size={"lg"}
                        >
                            Entrar
                        </Button>

                    <Link className={'text-primary custom-a text-center my-2'} to={'/recovery'}>Criar/Recuperar
                        senha</Link>
                    <small className={'text-center small'}> © {YEAR} | TokSaúde</small>
                </Row>
            </form>
        </Container>
    )
}
export default Login;
