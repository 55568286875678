import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";

const Layout = () => {
    const {user} = useAuth();

    if (!user) {
        return <Navigate to={"/login"}/>
    }

    return (
            <React.Fragment>
                <Outlet/>
            </React.Fragment>
    );
}

export default Layout;