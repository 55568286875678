import {Button} from "react-bootstrap";
import {HiOutlinePhoneMissedCall} from "react-icons/hi";
import {BsCameraVideo, BsCameraVideoOff, BsMic, BsMicMute} from "react-icons/bs";
import React, {useState} from "react";

const ControlsBar = ({setModal, handleMic, handleCam}) => {
    const [camIcon, setCamIcon] = useState(BsCameraVideo);
    const [audIcon, setAudIcon] = useState(BsMic);

    return (
        <React.Fragment>
            <div className={"w-100 d-flex justify-content-center gap-3 z-1"}>
                <Button className={"bg-white text-black rounded-5"}
                        style={{ width: '48px', height: '48px'}}
                        onClick={() => {

                    if (handleMic()) {
                        setAudIcon(<BsMic/>)
                    } else {
                        setAudIcon(<BsMicMute/>)
                    }
                }}>
                    {
                        audIcon
                    }
                </Button>
                <Button
                    style={{background: "#e63946", width: '48px', height: '48px'}}
                    className={"text-white btn-outline-danger rounded-5"}
                    onClick={() => {
                        setModal(true);
                    }}
                >
                    <HiOutlinePhoneMissedCall/>
                </Button>
                <Button
                    style={{width: '48px', height: '48px'}}
                    className={"bg-white text-black rounded-5"} onClick={() => {
                    if (handleCam()) {
                        setCamIcon(<BsCameraVideo/>)
                    } else {
                        setCamIcon(<BsCameraVideoOff/>)
                    }
                }}>
                    {
                        camIcon
                    }
                </Button>
            </div>
        </React.Fragment>
    )
}

export default ControlsBar;
